import React, { FunctionComponent } from 'react';
import { Intercept } from './components/Intercept';
import Routes from './route/Routes';
import { Layout } from './templates/Layout';
import { UserProvider } from './context/userContext';
import { NotificationProvider } from './context/notificationContext';
import { SurveyProvider } from './context/surveyContext';
import { GraphPaneProvider } from './context/integrantGraph/graphPaneContext';
import { IndividualSubscriptionDetailProvider } from 'context/individualSubscriptionDetailContext';
import { DocumentHashProvider } from 'context/documentHashContext';
import { OrganizationExhibitProvider } from 'context/organizationExhibitContext';
import { TestingLabProvider } from 'context/testingLabContext';
import { NIHProvider } from 'context/nihContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

const App: FunctionComponent = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
      <UserProvider>
        <SurveyProvider>
          <NotificationProvider>
            <GraphPaneProvider>
              <IndividualSubscriptionDetailProvider>
                <DocumentHashProvider>
                  <OrganizationExhibitProvider>
                    <TestingLabProvider>
                      <NIHProvider>
                        <Layout>
                          <Intercept>
                            <Routes />
                          </Intercept>
                        </Layout>
                      </NIHProvider>
                    </TestingLabProvider>
                  </OrganizationExhibitProvider>
                </DocumentHashProvider>
              </IndividualSubscriptionDetailProvider>
            </GraphPaneProvider>
          </NotificationProvider>
        </SurveyProvider>
      </UserProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
