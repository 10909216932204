import React, { CSSProperties, FunctionComponent, useState, useEffect } from 'react';
import { FiUser, FiChevronDown, FiPlusSquare } from 'react-icons/fi';
import { MdMenu, MdClear } from 'react-icons/md';
import { useAuthStore } from '../hooks/useAuthStore';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useTheme, useMediaQuery, Badge, MenuItem, Popover, makeStyles, Theme, createStyles } from '@material-ui/core';
import { ReactComponent as NotificationIcon } from '../static/notification-icon.svg';
import { useNotificationStore } from '../hooks/useNotificationStore';
import { useOrganizationStore } from '../hooks/useOrganizationStore';
import { subscriptionCategoriesLabel } from 'config';
import clsx from 'clsx';
import ReactTooltip from 'react-tooltip';
import { verifyEnvironment } from 'utils';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    menuDisable: {
      opacity: 0.7
    }
  })
);

const HeaderAuthenticated: FunctionComponent = () => {
  const classes = useStyle();
  const {
    isLoggedIn,
    logout,
    individual,
    individual: { temporary_password },
    isValidAuthToken,
    subscriptionList,
    setSelectedSubscription,
    selectedSubscription
  } = useAuthStore();
  const otpVerified = localStorage.getItem('otpVerified');
  const isLoginWithGoogle = localStorage.getItem('loginWithGoogle');
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down(900));
  const [show, setShow] = useState(false);
  const { initNotificationList, notificationList, initReadNotification } = useNotificationStore();
  const { selectedOrganization } = useOrganizationStore();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  useEffect(() => {
    if (
      isLoggedIn &&
      otpVerified &&
      selectedOrganization.id &&
      individual.access_token &&
      isValidAuthToken(individual) &&
      selectedSubscription === 'tracer'
    ) {
      initNotificationList();
    }
  }, [selectedOrganization.id, individual.access_token, isLoggedIn, otpVerified, selectedSubscription]);

  if (!isLoginWithGoogle && temporary_password) {
    return null;
  }
  const handleClose = (): any => {
    setAnchorEl(null);
  };

  const handleCollapse = (): void => {
    if (match) {
      setShow(false);
    }
  };

  const getNavUl = (): any => {
    return (
      <>
        <ReactTooltip />
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link to="/" className="nav-link" onClick={handleCollapse}>
              Dashboard
            </Link>
          </li>
          {/* <li className="nav-item"><Link to="/transferred" className="nav-link">View Batches(Other Organization)</Link></li> */}
          {/* <li className="nav-item"><Link to="/" className="nav-link">Create New Products</Link></li> */}
          <li className="nav-item dropdown">
            <div
              data-tip={
                !individual.is_valid_domain_name
                  ? 'To access this functionality first verify your domain.'
                  : !individual.is_approved
                  ? `Your organization's approval is pending from Admin side.`
                  : ''
              }
              className={clsx(
                'nav-link dropdown-toggle',
                !individual.is_valid_domain_name && classes.menuDisable,
                !individual.is_approved && classes.menuDisable
              )}
            >
              <FiPlusSquare />
              <p className="txt-name">Create</p>
              <FiChevronDown />
            </div>
            {individual.is_valid_domain_name && individual.is_approved && (
              <div className="dropdown-menu">
                {selectedSubscription === 'tracer' && (
                  <>
                    <Link className="dropdown-item" to="/create-new-product" onClick={handleCollapse}>
                      New Product
                    </Link>
                    <Link className="dropdown-item" to="/import-product-from-nih" onClick={handleCollapse}>
                      New Product From NIH
                    </Link>
                    <Link className="dropdown-item" to="/transferred" onClick={handleCollapse}>
                      Transfer In
                    </Link>
                    <Link className="dropdown-item" to="/survey" onClick={handleCollapse}>
                      Audience
                    </Link>
                  </>
                )}
                <Link className="dropdown-item" to="/ingredient-comparision" onClick={handleCollapse}>
                  Ingredient Comparision
                </Link>
              </div>
            )}
          </li>
          <li className="nav-item dropdown last">
            <div className="nav-link dropdown-toggle">
              <FiUser />
              <p className="txt-name">
                {individual.name_first} {individual.name_last}
              </p>
              <FiChevronDown />
            </div>
            <div className="dropdown-menu">
              <Link className="dropdown-item" to="/profile" onClick={handleCollapse}>
                Your Settings
              </Link>
              <Link className="dropdown-item" to="/organization" onClick={handleCollapse}>
                Your Organization
              </Link>
              <Link className="dropdown-item" to="/organization-exhibits" onClick={handleCollapse}>
                Organization Attachments
              </Link>
              <Link className="dropdown-item" to="/subscription" onClick={handleCollapse}>
                Subscription & Billing
              </Link>
              <Link className="dropdown-item" to="/plans" onClick={handleCollapse}>
                Manage Your Subscription
              </Link>
              <Link className="dropdown-item" to="/widget" onClick={handleCollapse}>
                Widget
              </Link>
              {selectedSubscription === 'tracer' && (
                <>
                  <Link className="dropdown-item" to="/csv" onClick={handleCollapse}>
                    Import Products
                  </Link>
                  <Link className="dropdown-item" to="/my-connections" onClick={handleCollapse}>
                    My Connections
                  </Link>
                </>
              )}
              <div className="dropdown-divider"></div>
              <a
                className="dropdown-item"
                href="mailto:contact@healthloq.com"
                onClick={handleCollapse}
                target="_blank"
                rel="noopener noreferrer"
              >
                Support
              </a>
              <p className="dropdown-item" onClick={logout}>
                Sign Out
              </p>
            </div>
          </li>
          {selectedSubscription && (
            <li className="nav-item dropdown">
              <div className="nav-link dropdown-toggle">
                <p className="txt-name text-capitalize">My Products</p>
                <FiChevronDown />
              </div>
              <div className="dropdown-menu">
                {subscriptionList?.map((item: any, key: number) => {
                  return (
                    <div
                      className={clsx(
                        'dropdown-item text-capitalize',
                        selectedSubscription === item && 'dropdown-item-selected'
                      )}
                      onClick={() => {
                        setSelectedSubscription(item);
                        handleCollapse();
                        history.push('/');
                      }}
                      key={key}
                    >
                      {subscriptionCategoriesLabel[item]}
                    </div>
                  );
                })}
              </div>
            </li>
          )}
          {selectedSubscription === 'tracer' && (
            <li className="nav-item" onClick={e => setAnchorEl(anchorEl ? null : e?.currentTarget)}>
              <p className="nav-link header-notification-icon">
                <Badge badgeContent={notificationList?.data?.length || 0} color="primary">
                  <NotificationIcon />
                </Badge>
              </p>
              {Boolean(anchorEl) && (
                <Popover
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                >
                  {notificationList?.data ? (
                    notificationList?.data?.length ? (
                      notificationList?.data?.map((item: any, key: number) => {
                        return (
                          <MenuItem
                            onClick={() => {
                              initReadNotification({ action_id: item?.action_id, type: item?.type });
                              handleClose();
                              history.push(`/survey-questions/${item?.action_id}/0`);
                            }}
                            key={key}
                          >
                            {item?.message}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem onClick={handleClose}>No new notifications.</MenuItem>
                    )
                  ) : (
                    <MenuItem onClick={handleClose}>No new notifications.</MenuItem>
                  )}
                </Popover>
              )}
            </li>
          )}
        </ul>
      </>
    );
  };
  return isLoggedIn && otpVerified ? (
    !match ? (
      getNavUl()
    ) : (
      <div className="navbar-mobile-right-side">
        <div className="navbar-menu-icon">
          {show ? <MdClear onClick={(): void => setShow(false)} /> : <MdMenu onClick={(): void => setShow(true)} />}
        </div>
        {show ? <div className="navbar-ul-root">{getNavUl()}</div> : null}
      </div>
    )
  ) : location.pathname === '/plans' ? (
    <div className="header-already-login">
      <div className="text-hal-subscription">Already have a subscription?</div>
      <Link to={'/login'}>Login</Link>
    </div>
  ) : null;
};

export { HeaderAuthenticated };
