import {
  createStyles,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { Layer } from 'common';
import { baseURL, apiURL, clientURL } from 'config';
import { useAuthStore } from 'hooks/useAuthStore';
import React, { useEffect, useState } from 'react';
import DocToolJWTGenerator from 'route/dashboard/verifier-publisher/DocToolJWTGenerator';
import { CopyToClipboardIcon } from 'static/icons';
const fontFamily =
  "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    docToolGuideContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      '&>h5': {
        marginBottom: 20,
        textAlign: 'center',
        fontSize: 60,
        fontWeight: '600'
      }
    },
    tabRoot: {
      textTransform: 'capitalize',
      color: '#000',
      fontWeight: 400,
      fontSize: 20,
      lineHeight: '21px',
      padding: '6px 8px',
      minWidth: 'max-content'
    },
    tabSelected: {
      color: '#008F2B'
    },
    tabsIndicator: {
      backgroundColor: '#008F2B'
    },
    tabsRoot: {
      borderBottom: `1px solid rgba(0,0,0,0.1)`
    },
    docToolStepsDiv: {
      marginBottom: 50,
      '& a': {
        color: '#008F2B'
      },
      '& li': {
        margin: '10px 0',
        fontFamily,
        fontSize: 20,
        lineHeight: 1.5,
        marginBottom: 20,
        '&>span': {
          color: '#008F2B'
        }
      },
      '&>h5': {
        margin: '50px 0 20px',
        fontFamily,
        fontWeight: '600',
        fontSize: 26
      }
    },
    envVariables: {
      margin: '10px 0',
      '&>h6': {
        margin: '20px 0',
        fontFamily,
        fontWeight: 'bold',
        borderBottom: `1px solid #ddd`,
        paddingBottom: 15
      },
      '&>p': {
        fontFamily,
        margin: '10px 0',
        padding: '4px 16px',
        display: 'inline-block',
        backgroundColor: '#e5e5e5',
        border: '1px solid #999',
        borderRadius: 5,
        marginLeft: 20,
        '&>span': {
          '&:nth-child(1)': {
            fontWeight: 600
          },
          '&:not(:nth-child(1))': {
            color: '#008F2B'
          }
        }
      },
      '&>div': {
        marginLeft: 20
      }
    },
    note: {
      fontSize: 16,
      lineHeight: '18px',
      color: 'rgba(0,0,0,0.5)',
      fontFamily,
      '&>span': {
        fontWeight: 600
      }
    },
    codeBlock: {
      borderRadius: 5,
      border: '1px solid #999',
      padding: '4px 16px',
      backgroundColor: '#e5e5e5',
      fontFamily,
      display: 'inline-block',
      fontSize: 20,
      lineHeight: 1.5,
      width: '100%',
      position: 'relative',
      '&>span': {
        position: 'absolute',
        top: 5,
        right: 5,
        borderRadius: 4,
        background: '#fff',
        padding: 5,
        width: 30,
        height: 30,
        cursor: 'pointer',
        boxShadow: '0 0 5px rgb(0 0 0 / 15%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&>svg': {
          width: 20,
          height: 20
        },
        '&:hover': {
          backgroundColor: '#EAF7ED',
          '&>svg': {
            fill: '#008F2B'
          }
        }
      }
    },
    codeBlockLi: {
      '&>div': {
        marginBottom: 20,
        '&:first-child': {
          marginTop: 20
        }
      }
    },
    onlyCodeBlockLi: {
      '&>div': {
        marginBottom: 20
      }
    },
    downLoadFile: {
      cursor: 'pointer',
      textDecoration: 'underline'
    },
    orHeader: {
      display: 'flex',
      justifyContent: 'center'
    }
  })
);

export const DocToolGuide = () => {
  const classes = useStyle();
  const theme = useTheme();
  const { isLoggedIn } = useAuthStore();
  const match = useMediaQuery(theme.breakpoints.down(768));
  const [activeTab, setActiveTab] = useState<number>(1);
  const [url, setUrl] = useState(null ?? '');
  const [copyToClipboardTooltipText, setCopyToClipboardTooltipText] = useState<string[]>(
    [...Array(35)].map(el => 'Copy')
  );
  const myConfig: any = window.MyConfig;
  const { REACT_APP_PRODUCER_URL_API } = myConfig;
  const handleCopyToClipboard = (str: string, index: number) => {
    setCopyToClipboardTooltipText((pre: string[]): string[] =>
      pre.map((el: string, i: number): string => (i === index ? 'Copied!' : el))
    );
    navigator.clipboard.writeText(str);
    setTimeout(() => {
      setCopyToClipboardTooltipText((pre: string[]): string[] =>
        pre.map((el: string, i: number): string => (i === index ? 'Copy' : el))
      );
    }, 5000);
  };

  const handleDownloadExe = () => {
    const api =
      REACT_APP_PRODUCER_URL_API !== '#{PRODUCER_URL_API}#'
        ? REACT_APP_PRODUCER_URL_API
        : process.env.REACT_APP_PRODUCER_URL_API;
    fetch(`${api}/exe-file/get`)
      .then(response => response.json())
      .then(data => setUrl(data?.file_url))
      .catch(error => {
        console.error('Error downloading file:', error);
      });
  };

  useEffect(() => {
    handleDownloadExe();
  }, []);
  return (
    <Layer
      direction="column"
      style={{ margin: '0 auto', padding: match ? 20 : '20px 0 0', maxWidth: '1320px' }}
      width={'100%'}
    >
      <div className={classes.docToolGuideContainer}>
        <Typography variant="h5">Document Hash Project Setup</Typography>
        <Tabs
          value={activeTab}
          onChange={(e: any, value: number): void => setActiveTab(value)}
          classes={{ indicator: classes.tabsIndicator, root: classes.tabsRoot }}
        >
          <Tab value={1} label="For Windows" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
          <Tab value={2} label="For Linux/MacOS" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
        </Tabs>
        <div className={classes.docToolStepsDiv}>
          {activeTab === 1 && (
            <>
              <ul>
                <li>
                  Install node js.&nbsp;
                  <a href="https://nodejs.org" target={'_blank'}>
                    Click here
                  </a>
                  &nbsp;to download node js.
                </li>
              </ul>
              <ol>
                <li>
                  <span className={classes.downLoadFile}>
                    <a href={url}>Download the .msi</a>
                  </span>{' '}
                  file and set up the Hash project on your system.
                </li>
              </ol>
              <Typography className={classes.orHeader} variant="h5">
                Or follow below steps
              </Typography>
            </>
          )}
          <ol>
            {activeTab === 2 && (
              <li>
                Install node js.&nbsp;
                <a href="https://nodejs.org" target={'_blank'}>
                  Click here
                </a>
                &nbsp;to download node js.
              </li>
            )}
            {activeTab === 1 ? (
              <li>
                Install Git for setup document-hash project from GitHub. Follow&nbsp;
                <a href="https://dev.to/supritha/how-to-install-and-configure-git-on-windows-os-8p4" target={'_blank'}>
                  How to install and configure Git on Windows OS?
                </a>
                &nbsp;blog to setup Git.
              </li>
            ) : (
              <li className={classes.codeBlockLi}>
                Install Git for setup document-hash project from GitHub. Follow the steps below to install Git.
                <div className={classes.codeBlock}>
                  sudo apt-get install git
                  <Tooltip arrow title={copyToClipboardTooltipText[0]}>
                    <span onClick={() => handleCopyToClipboard('sudo apt-get install git', 0)}>
                      <CopyToClipboardIcon />
                    </span>
                  </Tooltip>
                </div>
                <div className={classes.codeBlock}>
                  git --version
                  <Tooltip arrow title={copyToClipboardTooltipText[1]}>
                    <span onClick={() => handleCopyToClipboard('git --version', 1)}>
                      <CopyToClipboardIcon />
                    </span>
                  </Tooltip>
                </div>
              </li>
            )}

            <li>
              Setup document-hash project from GitHub repo.&nbsp;
              <a href="https://github.com/healthloq/hash-generator" target={'_blank'}>
                Click here
              </a>
              &nbsp;to setup the project or run the below command to clone github repository
            </li>
            <div className={classes.codeBlock}>
              git clone https://github.com/healthloq/hash-generator.git&nbsp;
              <Tooltip arrow title={copyToClipboardTooltipText[2]}>
                <span
                  onClick={() => handleCopyToClipboard('git clone https://github.com/healthloq/hash-generator.git', 2)}
                >
                  <CopyToClipboardIcon />
                </span>
              </Tooltip>
            </div>
            {activeTab === 1 ? (
              <li>
                Open document-hash project folder in <span>command prompt</span>.
              </li>
            ) : (
              <li>
                Open document-hash project folder in <span>terminal</span>.
              </li>
            )}
            {activeTab === 1 ? (
              <li>
                Setup env variables (You can use any editor to update env variables like <span>Notepad</span>, etc…)
              </li>
            ) : (
              <li>
                Setup env variables (You can use any editor to update env variables like <span>Text Editor</span>, etc…)
              </li>
            )}
            <div className={classes.envVariables}>
              <Typography variant="h6">For server env (hash-generator/.env)</Typography>
              <Typography variant="body1">
                <span>PORT</span> =&gt; 8003 (You can use anything like 8000, 9000, 8080, etc.)
              </Typography>
              <Typography variant="body1">
                <span>ROOT_FOLDER_PATH</span> =&gt; ./documents (Add your documents folder path which documents you want
                to&nbsp;
                <span>sync</span> with <span>HealthLOQ</span>.)
              </Typography>
              {isLoggedIn ? (
                <>
                  <Typography variant="body1">
                    <span>REACT_APP_JWT_TOKEN</span> =&gt;&nbsp; Click on below button to generate JWT token.
                  </Typography>
                  <DocToolJWTGenerator />
                </>
              ) : (
                <Typography variant="body1">
                  <span>REACT_APP_JWT_TOKEN</span> =&gt;&nbsp;
                  <a href={baseURL} target={'_blank'}>
                    Go to dashboard
                  </a>
                  &nbsp;and click on&nbsp;
                  <span>Generate Doc Tool JWT Token</span> Button to generate a token.
                </Typography>
              )}
              <Typography variant="body1">
                <span>REACT_APP_HEALTHLOQ_API_BASE_URL</span> =&gt; {apiURL} (Add HealthLOQ api base url.)
              </Typography>
              <Typography variant="body1">
                <span>REACT_APP_HEALTHLOQ_ORGANIZATION_APP_BASE_URL</span> =&gt; {baseURL}
                &nbsp;(Add HealthLOQ Organization App base URL.)
              </Typography>
              <Typography variant="body1">
                <span>REACT_APP_HEALTHLOQ_CONSUMER_APP_BASE_URL</span> =&gt; {clientURL}
                &nbsp;(Add HealthLOQ Consumer app base URL.)
              </Typography>

              <Typography variant="h6">For Client env (hash-generator/client/.env)</Typography>
              <Typography variant="body1">
                <span>REACT_APP_API_BASE_URL</span> =&gt; http://localhost:8003 (if you change port in server env then
                just replace this <span>8003</span> port with your custom port.)
              </Typography>
              <Typography variant="body1">
                <span>REACT_APP_JWT_TOKEN</span> =&gt; same as the server env <span>REACT_APP_JWT_TOKEN</span> variable.
              </Typography>
              <Typography variant="body1">
                <span>REACT_APP_HEALTHLOQ_API_BASE_URL</span> =&gt; {apiURL} (Add HealthLOQ api base url. same as
                server&nbsp;
                <span>REACT_APP_HEALTHLOQ_API_BASE_URL</span> variable.)
              </Typography>
              <Typography variant="body1">
                <span>REACT_APP_HEALTHLOQ_ORGANIZATION_APP_BASE_URL</span> =&gt; {baseURL}
                &nbsp;(Add HealthLOQ Organization App base URL.)
              </Typography>
              <Typography variant="body1">
                <span>REACT_APP_HEALTHLOQ_CONSUMER_APP_BASE_URL</span> =&gt; {clientURL}
                &nbsp;(Add HealthLOQ Consumer App base URL.)
              </Typography>
            </div>
            {activeTab === 1 ? (
              <li>
                Execute the script file using this <span>windows.cmd</span> command.
              </li>
            ) : (
              <li>
                Execute the script file using this <span>sh run.sh</span> command.
              </li>
            )}
            <li>
              To visit dashboard open{' '}
              <a href="http://localhost:8003" target="_blank">
                http://localhost:8003
              </a>
              &nbsp; (if you change port in server env then just replace this <span>8003</span> port with your custom
              port.)
            </li>
          </ol>
          <Typography variant="h5">
            In case the above script file is not working properly, then follow the below commands.
          </Typography>
          {activeTab === 1 ? (
            <div className={classes.onlyCodeBlockLi}>
              <div className={classes.codeBlock}>
                npm install
                <Tooltip arrow title={copyToClipboardTooltipText[3]}>
                  <span onClick={() => handleCopyToClipboard('npm install', 3)}>
                    <CopyToClipboardIcon />
                  </span>
                </Tooltip>
              </div>
              <div className={classes.codeBlock}>
                cd client
                <Tooltip arrow title={copyToClipboardTooltipText[4]}>
                  <span onClick={() => handleCopyToClipboard('cd client', 4)}>
                    <CopyToClipboardIcon />
                  </span>
                </Tooltip>
              </div>
              <div className={classes.codeBlock}>
                npm install
                <Tooltip arrow title={copyToClipboardTooltipText[5]}>
                  <span onClick={() => handleCopyToClipboard('npm install', 5)}>
                    <CopyToClipboardIcon />
                  </span>
                </Tooltip>
              </div>
              <div className={classes.codeBlock}>
                npm run build
                <Tooltip arrow title={copyToClipboardTooltipText[6]}>
                  <span onClick={() => handleCopyToClipboard('npm run build', 6)}>
                    <CopyToClipboardIcon />
                  </span>
                </Tooltip>
              </div>
              <div className={classes.codeBlock}>
                cd ..
                <Tooltip arrow title={copyToClipboardTooltipText[7]}>
                  <span onClick={() => handleCopyToClipboard('cd ..', 7)}>
                    <CopyToClipboardIcon />
                  </span>
                </Tooltip>
              </div>
              <div className={classes.codeBlock}>
                npm start
                <Tooltip arrow title={copyToClipboardTooltipText[8]}>
                  <span onClick={() => handleCopyToClipboard('npm start', 8)}>
                    <CopyToClipboardIcon />
                  </span>
                </Tooltip>
              </div>
            </div>
          ) : (
            <div className={classes.onlyCodeBlockLi}>
              <div className={classes.codeBlock}>
                npm install
                <Tooltip arrow title={copyToClipboardTooltipText[9]}>
                  <span onClick={() => handleCopyToClipboard('npm install', 9)}>
                    <CopyToClipboardIcon />
                  </span>
                </Tooltip>
              </div>
              <div className={classes.codeBlock}>
                cd client
                <Tooltip arrow title={copyToClipboardTooltipText[10]}>
                  <span onClick={() => handleCopyToClipboard('cd client', 10)}>
                    <CopyToClipboardIcon />
                  </span>
                </Tooltip>
              </div>
              <div className={classes.codeBlock}>
                npm install
                <Tooltip arrow title={copyToClipboardTooltipText[11]}>
                  <span onClick={() => handleCopyToClipboard('npm install', 11)}>
                    <CopyToClipboardIcon />
                  </span>
                </Tooltip>
              </div>
              <div className={classes.codeBlock}>
                npm run build
                <Tooltip arrow title={copyToClipboardTooltipText[12]}>
                  <span onClick={() => handleCopyToClipboard('npm run build', 12)}>
                    <CopyToClipboardIcon />
                  </span>
                </Tooltip>
              </div>
              <div className={classes.codeBlock}>
                cd ..
                <Tooltip arrow title={copyToClipboardTooltipText[13]}>
                  <span onClick={() => handleCopyToClipboard('cd ..', 13)}>
                    <CopyToClipboardIcon />
                  </span>
                </Tooltip>
              </div>
              <div className={classes.codeBlock}>
                sudo npm install pm2 -g
                <Tooltip arrow title={copyToClipboardTooltipText[14]}>
                  <span onClick={() => handleCopyToClipboard('sudo npm install pm2 -g', 14)}>
                    <CopyToClipboardIcon />
                  </span>
                </Tooltip>
              </div>
              <div className={classes.codeBlock}>
                pm2 list
                <Tooltip arrow title={copyToClipboardTooltipText[15]}>
                  <span onClick={() => handleCopyToClipboard('pm2 list', 15)}>
                    <CopyToClipboardIcon />
                  </span>
                </Tooltip>
              </div>
              <div className={classes.codeBlock}>
                pm2 start healthloqdocverify.js --name=healthloqdocverify
                <Tooltip arrow title={copyToClipboardTooltipText[16]}>
                  <span
                    onClick={() =>
                      handleCopyToClipboard('pm2 start healthloqdocverify.js --name=healthloqdocverify', 16)
                    }
                  >
                    <CopyToClipboardIcon />
                  </span>
                </Tooltip>
              </div>
              <div className={classes.codeBlock}>
                pm2 list
                <Tooltip arrow title={copyToClipboardTooltipText[17]}>
                  <span onClick={() => handleCopyToClipboard('pm2 list', 17)}>
                    <CopyToClipboardIcon />
                  </span>
                </Tooltip>
              </div>
              <div className={classes.codeBlock}>
                pm2 log healthloqdocverify
                <Tooltip arrow title={copyToClipboardTooltipText[18]}>
                  <span onClick={() => handleCopyToClipboard('pm2 log healthloqdocverify', 18)}>
                    <CopyToClipboardIcon />
                  </span>
                </Tooltip>
              </div>
            </div>
          )}

          {activeTab === 1 ? (
            <>
              <Typography variant="h5">Steps to set up this project as a windows background service</Typography>
              <Typography variant="body2" className={classes.note}>
                <span>Note:</span> if you don't want to run this project as your system background process, then skip
                this step.
              </Typography>
              <ol>
                <li>
                  Download the NSSM setup file from&nbsp;
                  <a href="https://nssm.cc/download" target={'_blank'}>
                    HERE
                  </a>
                  .
                </li>
                <li>Open command prompt as Administrator.</li>
                <li>Open the NSSM folder in the command prompt in which the nssm.exe file is available.</li>
                <li className={classes.codeBlockLi}>
                  Run below commands.
                  <div className={classes.codeBlock}>
                    nssm install healthloqdocverify "C:\Program Files\nodejs\node.exe"
                    <Tooltip arrow title={copyToClipboardTooltipText[19]}>
                      <span
                        onClick={() =>
                          handleCopyToClipboard('nssm install healthloqdocverify "C:Program Files\nodejs\node.exe"', 19)
                        }
                      >
                        <CopyToClipboardIcon />
                      </span>
                    </Tooltip>
                  </div>
                  <div className={classes.codeBlock}>
                    nssm set healthloqdocverify AppDirectory "PROJECT_FOLDER_PATH"
                    <Tooltip arrow title={copyToClipboardTooltipText[20]}>
                      <span
                        onClick={() =>
                          handleCopyToClipboard('nssm set healthloqdocverify AppDirectory "PROJECT_FOLDER_PATH"', 20)
                        }
                      >
                        <CopyToClipboardIcon />
                      </span>
                    </Tooltip>
                  </div>
                  <div className={classes.codeBlock}>
                    nssm set healthloqdocverify AppParameters healthloqdocverify.js
                    <Tooltip arrow title={copyToClipboardTooltipText[21]}>
                      <span
                        onClick={() =>
                          handleCopyToClipboard('nssm set healthloqdocverify AppParameters healthloqdocverify.js', 21)
                        }
                      >
                        <CopyToClipboardIcon />
                      </span>
                    </Tooltip>
                  </div>
                  <div className={classes.codeBlock}>
                    nssm set healthloqdocverify DisplayName "HealthLOQ documents sync and verification tool."
                    <Tooltip arrow title={copyToClipboardTooltipText[22]}>
                      <span
                        onClick={() =>
                          handleCopyToClipboard(
                            'nssm set healthloqdocverify DisplayName "HealthLOQ documents sync and verification tool."',
                            22
                          )
                        }
                      >
                        <CopyToClipboardIcon />
                      </span>
                    </Tooltip>
                  </div>
                  <div className={classes.codeBlock}>
                    nssm start healthloqdocverify
                    <Tooltip arrow title={copyToClipboardTooltipText[23]}>
                      <span onClick={() => handleCopyToClipboard('nssm start healthloqdocverify', 23)}>
                        <CopyToClipboardIcon />
                      </span>
                    </Tooltip>
                  </div>
                </li>
              </ol>
              <Typography variant="body2" className={classes.note}>
                <span>Note:</span> in the above command, <span>healthloqdocverify</span> is the service name. So, if you
                want to add your custom service name, just replace this default name with your custom name in all
                commands.
              </Typography>
              <Typography variant="body1" style={{ margin: '20px 0 0' }}>
                If you don't understand the above points, visit&nbsp;
                <a href="https://www.helpmegeek.com/run-nodejs-application-as-windows-service" target={'_blank'}>
                  How to Run NodeJS as a Windows Service
                </a>
                &nbsp;blog for further processes.
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h5">Steps to set up this project as your system background service</Typography>
              <Typography variant="body1">
                Follow&nbsp;
                <a href="https://pm2.keymetrics.io/docs/usage/startup" target={'_blank'}>
                  Startup Script Generator
                </a>
                &nbsp;documentation for restarting pm2 after the system boots or restarts.
              </Typography>
            </>
          )}
          <Typography variant="h5">Notes</Typography>
          <ul>
            <li>
              If you set up this project for the first time and after document verification you will not get any csv,
              then please check your popup permission in the browser search bar.
            </li>
          </ul>
          <Typography variant="h5">Steps to update Doc Tool into the latest version</Typography>
          {activeTab === 1 ? (
            <ol>
              <li>
                Open document-hash project folder in <span>command prompt</span>.
              </li>
              <li>Run to the command below to get the latest update to your PC or Laptop.</li>
              <div className={classes.codeBlock}>
                git pull origin main
                <Tooltip arrow title={copyToClipboardTooltipText[24]}>
                  <span onClick={() => handleCopyToClipboard('git pull origin main', 24)}>
                    <CopyToClipboardIcon />
                  </span>
                </Tooltip>
              </div>
              <li>Verify both env variables. If you get any new variables, then set them with old env variables.</li>
              <li>
                Execute the script file using this <span>windows.cmd</span> command.
              </li>
              <li>
                If you set this project as a windows background service, then restart the service using the steps below.
                <ul>
                  <li>Open command prompt as Administrator.</li>
                  <li>
                    Open the NSSM folder in the command prompt in which the nssm.exe file is available and run the
                    command below.
                  </li>
                  <div className={classes.codeBlock}>
                    nssm restart healthloqdocverify
                    <Tooltip arrow title={copyToClipboardTooltipText[25]}>
                      <span onClick={() => handleCopyToClipboard('nssm restart healthloqdocverify', 25)}>
                        <CopyToClipboardIcon />
                      </span>
                    </Tooltip>
                  </div>
                  <Typography variant="body2" className={classes.note} style={{ marginTop: 20 }}>
                    <span>Note:</span> in the above command, <span>healthloqdocverify</span> is the service name. So if
                    you have used another name, then just replace this default name with your custom name in command.
                  </Typography>
                </ul>
              </li>
            </ol>
          ) : (
            <ol>
              <li>
                Open document-hash project folder in <span>terminal</span>.
              </li>
              <li className={classes.codeBlockLi}>
                Run to the command below to get the latest update to your PC or Laptop.
                <div className={classes.codeBlock}>
                  git pull origin main
                  <Tooltip arrow title={copyToClipboardTooltipText[26]}>
                    <span onClick={() => handleCopyToClipboard('git pull origin main', 26)}>
                      <CopyToClipboardIcon />
                    </span>
                  </Tooltip>
                </div>
              </li>
              <li className={classes.codeBlockLi}>
                Verify both env variables. If you get any new variables, then set them with old env variables and follow
                the steps below.
                <div className={classes.codeBlock}>
                  npm install
                  <Tooltip arrow title={copyToClipboardTooltipText[27]}>
                    <span onClick={() => handleCopyToClipboard('npm install', 27)}>
                      <CopyToClipboardIcon />
                    </span>
                  </Tooltip>
                </div>
                <div className={classes.codeBlock}>
                  cd client
                  <Tooltip arrow title={copyToClipboardTooltipText[28]}>
                    <span onClick={() => handleCopyToClipboard('cd client', 28)}>
                      <CopyToClipboardIcon />
                    </span>
                  </Tooltip>
                </div>
                <div className={classes.codeBlock}>
                  npm install
                  <Tooltip arrow title={copyToClipboardTooltipText[29]}>
                    <span onClick={() => handleCopyToClipboard('npm install', 29)}>
                      <CopyToClipboardIcon />
                    </span>
                  </Tooltip>
                </div>
                <div className={classes.codeBlock}>
                  npm run build
                  <Tooltip arrow title={copyToClipboardTooltipText[30]}>
                    <span onClick={() => handleCopyToClipboard('npm run build', 30)}>
                      <CopyToClipboardIcon />
                    </span>
                  </Tooltip>
                </div>
                <div className={classes.codeBlock}>
                  cd ..
                  <Tooltip arrow title={copyToClipboardTooltipText[31]}>
                    <span onClick={() => handleCopyToClipboard('cd ..', 31)}>
                      <CopyToClipboardIcon />
                    </span>
                  </Tooltip>
                </div>
              </li>
              <li>
                If you set this project as a system background service, then restart the service using the steps below.
                <ul>
                  <li className={classes.codeBlockLi}>
                    Open command prompt as Administrator and run the commands below one by one.
                    <div className={classes.codeBlock}>
                      pm2 list
                      <Tooltip arrow title={copyToClipboardTooltipText[32]}>
                        <span onClick={() => handleCopyToClipboard('pm2 list', 32)}>
                          <CopyToClipboardIcon />
                        </span>
                      </Tooltip>
                    </div>
                    <div className={classes.codeBlock}>
                      pm2 restart healthloqdocverify
                      <Tooltip arrow title={copyToClipboardTooltipText[33]}>
                        <span onClick={() => handleCopyToClipboard('pm2 restart healthloqdocverify', 33)}>
                          <CopyToClipboardIcon />
                        </span>
                      </Tooltip>
                    </div>
                    <div className={classes.codeBlock}>
                      pm2 log healthloqdocverify
                      <Tooltip arrow title={copyToClipboardTooltipText[34]}>
                        <span onClick={() => handleCopyToClipboard('pm2 log healthloqdocverify', 34)}>
                          <CopyToClipboardIcon />
                        </span>
                      </Tooltip>
                    </div>
                  </li>
                </ul>
              </li>
            </ol>
          )}
        </div>
      </div>
    </Layer>
  );
};
export default DocToolGuide;
