import { ICreateIndividualDTO, IIndividualAuthenticatedDTO, IIndividualDetailDTO } from '../../lib/individual';
import { ILoginDTO } from '../../lib/auth';
import { useCookie } from '@use-hook/use-cookie';
import { useCallback, useContext, useEffect, useState } from 'react';
import { organization_ } from '../common/models';
import { cookieDuration, rememberMeCookieDuration } from '../config';
import { AuthContext, IAuthProviderState } from '../context/authContext';
import { useAuthService } from '../hooks/api/useAuthService';
import { IIndividualRole } from '../types/common';
import { useOrganizationStore } from './useOrganizationStore';
import { useRouter } from './useRouter';
import { useLocation } from 'react-router-dom';
import { useToastStore } from '../hooks/useToastStore';

interface IUseAuthStore extends IAuthProviderState {
  login: (userCredentials: ILoginDTO) => Promise<void>;
  signup: (user: ICreateIndividualDTO) => Promise<IIndividualDetailDTO | undefined>;
  logout: () => void;
  logout1: () => void;
  setIndividual: (individualDetail: any) => void;
  setIndividualRoles: (individualRoles: IIndividualRole[]) => void;
  setThreshold: (thresholdObj: any) => void;
  isValidAuthToken: (data: any) => boolean;
  initVerifyOTP: (obj: any) => Promise<any>;
  initResendOTP: () => Promise<any>;
  setSelectedSubscription: (str: any) => void;
  addNewSubscription: (data: any) => void;
  updateDomainInfo: (data: any) => void;
  loginSSO: (data: any) => void;
}

const useAuthStore = (): IUseAuthStore => {
  const location: any = useLocation();
  const [state, dispatch] = useContext(AuthContext);
  const { post, register, verifyOTP, resendOTP, ssoLogin } = useAuthService();
  const { isOpenToast } = useToastStore();
  const [logoutTimeout, setLogoutTimeout] = useState<any>(null);
  const {
    isLoggedIn,
    isError,
    individual,
    individualRoles,
    initialUrlPath,
    isLoading,
    threshold,
    verifyOTPData,
    resendOTPData,
    subscriptionList,
    selectedSubscription
  } = state;
  const [individualCookie, setIndividualCookie] = useCookie('hl-individual', '');
  const [isLoggedInWithCredentials, setLoggedInWithCredentials] = useState(false);
  const { setOrganization } = useOrganizationStore();

  const setSelectedSubscription: IUseAuthStore['setSelectedSubscription'] = (str: any) => {
    dispatch({ type: 'setSelectedSubscription', payload: str });
    localStorage.setItem('selectedSubscription', str);
  };

  const { navigate } = useRouter();

  const setIndividualRoles: IUseAuthStore['setIndividualRoles'] = useCallback(
    () => (individualRoles: IIndividualRole[]): void => {
      dispatch({ type: 'setIndividualRoles', payload: individualRoles });
    },
    [dispatch]
  );

  const handleLogout = useCallback((): void => {
    setIndividualRoles([]);
    setIndividualCookie('');
    setOrganization(organization_);
    sessionStorage.removeItem('item');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('refererObj');
    //window.location.replace("/");
    navigate('/');
    window.location.reload();
    isOpenToast('success', 'Sign Out Successful');
  }, [setIndividualCookie, setIndividualRoles, setOrganization]);

  const logout: IUseAuthStore['logout'] = useCallback(() => {
    localStorage.removeItem('otpVerified');
    localStorage.removeItem('loginWithGoogle');
    localStorage.removeItem('loginWithMicrosoft');
    dispatch({ type: 'setLoggedIn', payload: false });
    dispatch({ type: 'setInitialUrlPath', payload: '/' });
    handleLogout();
  }, [dispatch, handleLogout]);

  const logout1: IUseAuthStore['logout'] = useCallback(() => {
    dispatch({ type: 'setLoggedIn', payload: false });
    localStorage.removeItem('otpVerified');
    setIndividualRoles([]);
    setIndividualCookie('');
    setOrganization(organization_);
    sessionStorage.removeItem('refererObj');
    sessionStorage.removeItem('item');
    sessionStorage.removeItem('userData');
    //isOpenToast('success', "Sign Out Successful");
  }, [dispatch, handleLogout]);

  const handleExpire = useCallback(
    (expires: number): void => {
      if (logoutTimeout) clearTimeout(logoutTimeout);
      const date = new Date();
      setLogoutTimeout(
        setTimeout(() => {
          logout();
        }, expires - date.getTime())
      );
    },
    [logout]
  );

  const loginSuccess = useCallback(
    async (individualDetail: any & { expires: number }): Promise<void> => {
      if (isLoggedIn === false) {
        handleExpire(individualDetail.expires);
        setIndividualRoles(individualDetail.roles as IIndividualRole[]);
        const { organization_id, id } = individualDetail;
        const updatedOrganizationObj = {
          id: organization_id,
          account_id: id,
          description: '',
          logo_url: '',
          name: '',
          short_code: '',
          tag_line: ''
        };
        setOrganization(updatedOrganizationObj);
        dispatch({ type: 'setError', payload: false });
        dispatch({ type: 'setLoading', payload: false });
        dispatch({ type: 'setSubscriptionList', payload: individualDetail?.subscriptionList || [] });
        dispatch({ type: 'setIndividual', payload: individualDetail });
        dispatch({ type: 'setLoggedIn', payload: true });
        if (individualDetail?.subscriptionList?.length) {
          const selectedSubscription = localStorage.getItem('selectedSubscription');
          if (!selectedSubscription || !individualDetail?.subscriptionList?.includes(selectedSubscription))
            localStorage.setItem(
              'selectedSubscription',
              individualDetail?.subscriptionList?.includes('tracer') ? 'tracer' : individualDetail?.subscriptionList[0]
            );
          if (selectedSubscription && individualDetail?.subscriptionList?.includes(selectedSubscription)) {
            dispatch({ type: 'setSelectedSubscription', payload: selectedSubscription });
          } else {
            dispatch({
              type: 'setSelectedSubscription',
              payload: individualDetail?.subscriptionList?.includes('tracer')
                ? 'tracer'
                : individualDetail?.subscriptionList[0]
            });
          }
        }
        if (typeof individualDetail === 'object' && individualDetail.transaction_id === null) {
          const { search, pathname } = location;
          if (search === '?canceled=true' || pathname === '/thankyou') {
          } else {
            individualDetail.subscriptionValue = 0;
            sessionStorage.setItem('userData', JSON.stringify(individualDetail));
            navigate('/plans');
          }
        } else {
          const { temporary_password } = individualDetail;
          const isLoginWithGoogle = localStorage.getItem('loginWithGoogle');
          const isLoginWithMicrosoft = localStorage.getItem('loginWithMicrosoft');

          if (!isLoginWithGoogle && !isLoginWithMicrosoft && temporary_password) {
            navigate('/update-password');
          } else {
            const otpVerified = localStorage.getItem('otpVerified');
            navigate(
              initialUrlPath === '/login'
                ? otpVerified
                  ? '/'
                  : '/verify-otp'
                : otpVerified
                ? initialUrlPath
                : '/verify-otp'
            );
          }
          sessionStorage.removeItem('refererObj');
          sessionStorage.removeItem('item');
          sessionStorage.removeItem('userData');
        }
      }
    },
    [dispatch, handleExpire, isLoggedIn, initialUrlPath, navigate, setOrganization, setIndividualRoles]
  );

  useEffect(() => {
    if (individualCookie !== '' && individual.id === '' && isLoggedInWithCredentials === false) {
      const individualDetailDTO: IIndividualAuthenticatedDTO & {
        expires: number;
        otpVerified: boolean;
      } = JSON.parse(individualCookie);
      if (individualDetailDTO.otpVerified) {
        localStorage.setItem('otpVerified', 'true');
      }
      loginSuccess(individualDetailDTO);
    }
  }, [individualCookie, loginSuccess, individual.id, isLoggedInWithCredentials]);

  const loginWithIndividual = (individualDetailDTO: IIndividualAuthenticatedDTO): void => {
    const date = new Date();
    const exp_date = date.setTime(date.getTime() + cookieDuration);
    setIndividualCookie(
      { ...individualDetailDTO, expires: exp_date, otpVerified: false },
      {
        expires: 1
      }
    );
    loginSuccess({ ...individualDetailDTO, expires: exp_date });
  };

  const login: IUseAuthStore['login'] = async (userCredentials: ILoginDTO) => {
    dispatch({ type: 'setLoading', payload: true });
    setLoggedInWithCredentials(true);
    const individualDetailDTO: any = await post(userCredentials);
    if (
      individualDetailDTO &&
      !individualDetailDTO.statusCode &&
      typeof individualDetailDTO === 'object' &&
      Object.keys(individualDetailDTO)?.length
    ) {
      loginWithIndividual(individualDetailDTO);
    } else {
      dispatch({ type: 'setLoading', payload: false });
      dispatch({ type: 'setError', payload: true });
      isOpenToast('isError', individualDetailDTO?.message || 'Something went wrong! Please try after sometime.');
    }
  };

  const loginSSO: IUseAuthStore['loginSSO'] = async (userCredentials: any) => {
    dispatch({ type: 'setLoading', payload: true });
    setLoggedInWithCredentials(true);
    const individualDetailDTO: any = await ssoLogin(userCredentials);
    if (
      individualDetailDTO &&
      !individualDetailDTO.statusCode &&
      typeof individualDetailDTO === 'object' &&
      Object.keys(individualDetailDTO)?.length
    ) {
      dispatch({ type: 'setIndividual', payload: { ...individual, access_token: individualDetailDTO.token } });
      const date = new Date();
      let exp_date = date.setTime(date.getTime() + cookieDuration);
      handleExpire(exp_date);
      setIndividualCookie(
        {
          ...individualDetailDTO,
          access_token: individualDetailDTO.token,
          last_login: individualDetailDTO?.last_login,
          expires: exp_date,
          otpVerified: true
        },
        {
          expires: 1
        }
      );
      localStorage.setItem('otpVerified', 'true');
      localStorage.setItem('loginWithGoogle', 'true');
      localStorage.setItem('loginWithMicrosoft', 'true');
      loginWithIndividual(individualDetailDTO);
    } else {
      dispatch({ type: 'setLoading', payload: false });
      dispatch({ type: 'setError', payload: true });
      isOpenToast('isError', individualDetailDTO?.message || 'Something went wrong! Please try after sometime.');
    }
  };

  const signup: IUseAuthStore['signup'] = async userCredentials => {
    dispatch({ type: 'setLoading', payload: true });
    const individualDetailDTO = await register(userCredentials);
    dispatch({ type: 'setLoading', payload: false });
    return individualDetailDTO;
  };

  const initVerifyOTP: IUseAuthStore['initVerifyOTP'] = async (obj: any) => {
    dispatch({ type: 'setVerifyOTPData', payload: { loading: true } });
    const data = await verifyOTP(obj, individual.access_token);
    isOpenToast(data.status === '1' ? 'success' : 'isError', data?.message);
    dispatch({ type: 'setVerifyOTPData', payload: { loading: false, ...data } });
    if (data.status === '1') {
      dispatch({ type: 'setIndividual', payload: { ...individual, access_token: data.data.token } });
      const date = new Date();
      let exp_date = date.setTime(date.getTime() + cookieDuration);
      if (obj.rememberMe) {
        exp_date = date.setTime(date.getTime() + rememberMeCookieDuration);
      }
      handleExpire(exp_date);
      const cookieData = JSON.parse(individualCookie);
      setIndividualCookie(
        {
          ...cookieData,
          access_token: data.data.token,
          last_login: data?.data?.last_login,
          expires: exp_date,
          otpVerified: true
        },
        {
          expires: obj.rememberMe ? 7 : 1
        }
      );
      localStorage.setItem('otpVerified', 'true');
      if (cookieData?.temporary_password) {
        navigate('/update-password');
      } else if (cookieData?.is_valid_domain_name) {
        navigate('/');
      } else {
        navigate('/organization');
      }
    }
  };

  const initResendOTP: IUseAuthStore['initResendOTP'] = async () => {
    dispatch({ type: 'setResendOTPData', payload: { loading: true } });
    const data = await resendOTP(individual);
    isOpenToast(data.status === '1' ? 'success' : 'isError', data.message);
    dispatch({ type: 'setResendOTPData', payload: { loading: false, ...data } });
  };

  const setThreshold: IUseAuthStore['setThreshold'] = async (thresholdObj: any) => {
    dispatch({ type: 'setLoading', payload: thresholdObj });
  };

  const setIndividual = (individualDetail: any) => {
    dispatch({ type: 'setIndividual', payload: individualDetail });
  };

  const addNewSubscription: IUseAuthStore['addNewSubscription'] = async (data: any) => {
    dispatch({ type: 'setIndividual', payload: { ...individual, access_token: data.token } });
    const cookieData = JSON.parse(individualCookie);
    const exp_date = data?.expiresIn;
    handleExpire(exp_date);
    const newSubscriptionList = [...subscriptionList, data.subscription_type];
    setIndividualCookie({
      ...cookieData,
      access_token: data.token,
      expires: exp_date,
      subscriptionList: newSubscriptionList
    });
    dispatch({ type: 'setSubscriptionList', payload: newSubscriptionList });
  };

  const isValidAuthToken = (data: any): boolean => {
    if (data) {
      const expires = data.expires;
      if (expires && new Date().getTime() < expires) {
        return true;
      }
    }
    return false;
  };

  const updateDomainInfo: IUseAuthStore['updateDomainInfo'] = (data: any) => {
    const cookieData = JSON.parse(individualCookie);
    setIndividualCookie({
      ...cookieData,
      ...data
    });
    dispatch({
      type: 'setIndividual',
      payload: {
        ...individual,
        ...data
      }
    });
  };

  return {
    threshold,
    setThreshold,
    isLoading,
    isLoggedIn,
    login,
    isError,
    individual,
    individualRoles,
    setIndividualRoles,
    logout,
    logout1,
    initialUrlPath,
    setIndividual,
    signup,
    isValidAuthToken,
    resendOTPData,
    verifyOTPData,
    initVerifyOTP,
    initResendOTP,
    subscriptionList,
    selectedSubscription,
    setSelectedSubscription,
    addNewSubscription,
    updateDomainInfo,
    loginSSO
  };
};

export { useAuthStore };
