import React, { CSSProperties, FunctionComponent, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Input } from '../../common/Input';
import { Layer } from '../../common/Layer';
import { useAuthStore } from '../../hooks/useAuthStore';
import { defaultBoxShadow, defaultGreyBackgroundColor } from '../../styles/default';
import { isEmptyString, validateEmail } from '../../utils';
import { useToastStore } from '../../hooks/useToastStore';
import { PrimaryButton } from 'common/Button/buttons';
import { Button, createStyles, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { FcGoogle } from 'react-icons/fc';
import axios from 'axios';
import { PublicClientApplication } from '@azure/msal-browser';
import { MicroSoftIcon } from 'static/icons';

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID || ''
  }
};

const labelSpacingStyle: CSSProperties = { marginBottom: 20, display: 'block' };
const contentWrapperStyle: CSSProperties = {
  boxShadow: defaultBoxShadow,
  maxWidth: 600,
  width: '95%',
  padding: 20,
  backgroundColor: 'white'
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hLoginFormOR: {
      marginTop: '10px !important',
      marginBottom: '10px !important',
      width: '100% !important',
      '&>p': {
        textAlign: 'center',
        position: 'relative',
        '&:before': {
          top: '50%',
          width: '80%',
          height: '1px',
          content: "''",
          position: 'absolute',
          transform: 'translate(-50%,-50%)',
          left: '50%',
          backgroundColor: '#28a745'
        },
        '&>span': {
          padding: '0 5px',
          position: 'relative',
          backgroundColor: '#fff'
        }
      }
    },
    loginButton: {
      display: 'flex',
      justifyContent: 'center',
      gap: '5px',
      padding: '15px'
    },
    loginButton1: {
      display: 'flex',
      justifyContent: 'center',
      '&>button': {
        padding: '8px 50px'
      }
    }
  })
);
const defaults = {
  email_address: '',
  password: ''
};
const Login: FunctionComponent = () => {
  const classes = useStyles();
  const [credentials, setCredentials] = useState(defaults);
  const { login, isLoading, isError, loginSSO } = useAuthStore();
  const { isOpenToast } = useToastStore();
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (): Promise<void> => {
    if (isEmptyString(credentials.email_address) || isEmptyString(credentials.password)) {
      isOpenToast('warn', 'Username or Password cannot be empty.');
    } else if (validateEmail(credentials.email_address)) {
      isOpenToast('warn', 'Please enter a valid email address.');
    } else {
      await login(credentials);
    }
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (credentialResponse: any) => {
      try {
        const userInfoResponse = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${credentialResponse.access_token}`
          }
        });
        const { email } = userInfoResponse.data;
        const payload = {
          email_address: email,
          is_google_login: true
        };
        loginSSO(payload);
      } catch (error) {
        throw new Error('Something went wrong');
      }
    },
    onError: () => {
      console.log('Login Failed');
    }
  });

  const authHandler = (err: any, data: any) => {
    console.log(err, data);
  };

  const handleSsoLoginMicrosoft = async () => {
    try {
      const msalInstance = new PublicClientApplication(msalConfig);
      await msalInstance.initialize();
      const loginSuccess = await msalInstance.loginPopup({
        redirectUri: 'https://healthloqproducer-dev.azurewebsites.net',
        // redirectUri: 'http://localhost:3000',
        scopes: ['user.read'],
        prompt: 'consent'
      });
      const userInfoResponse = await axios.get('https://graph.microsoft.com/v1.0/me', {
        headers: {
          Authorization: `Bearer ${loginSuccess?.accessToken}`
        }
      });

      const payload = {
        email_address: userInfoResponse.data.mail,
        is_microsoft_login: true
      };

      if (userInfoResponse.data.mail) {
        loginSSO(payload);
      } else {
        console.log('=============');
        return false;
      }
    } catch (error) {
      console.log('----------', error);
    }
  };

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.data.type === 'success') {
        const payload = {
          email_address: event.data.data.email_address,
          is_microsoft_login: true
        };
        loginSSO(payload);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);

  useEffect(() => {
    const checkElement = () => {
      const element = document.getElementById('microsoft-sso-login-button');
      const elementClass = document.getElementsByClassName('mui-outlined-button');
      if (element && elementClass.length > 0) {
        setIsComponentLoaded(true);
      }
    };

    // Check immediately and add a mutation observer if necessary
    checkElement();

    const observer = new MutationObserver(() => {
      checkElement();
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, [window.location.pathname]);

  return (
    <Layer
      center
      fill
      style={{ position: 'relative', backgroundColor: defaultGreyBackgroundColor, height: 'calc(100vh - 70px)' }}
      direction="column"
      width={'100%'}
    >
      <Layer direction="column" style={contentWrapperStyle}>
        <h2>Welcome to HealthLoq</h2>
        <p>Build, Edit And Publish Batches.</p>
        <br />

        <label style={labelSpacingStyle} className="col-md-12">
          Email
          <Input
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
              setCredentials({ ...credentials, email_address: event.currentTarget.value })
            }
            value={credentials.email_address}
            type="email"
            autoFocus
            placeholder="e.g. Dwight.Schrute@healthloq.com"
          ></Input>
        </label>
        <label style={labelSpacingStyle} className="col-md-12">
          Password
          <div style={{ position: 'relative', width: '100%' }}>
            <Input
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                setCredentials({ ...credentials, password: event.currentTarget.value })
              }
              value={credentials.password}
              type={showPassword ? 'text' : 'password'}
            ></Input>
            <IconButton
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              style={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                padding: '0',
                fontSize: '14px'
              }}
            >
              {!showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </div>
        </label>
        {/* <p className="loginError">{isError && `Incorrect Username or Password`}</p> */}
        <Grid className={classes.loginButton1}>
          <PrimaryButton
            children={'Login'}
            icon="signin"
            secondaryButton={true}
            maxContent={true}
            loading={isLoading}
            disabled={isLoading}
            onClick={handleLogin}
          />
        </Grid>
        {/* <p>
          Need an account? <Link to="/register">Register</Link>
        </p> */}
        <Grid className={classes.hLoginFormOR}>
          <Typography variant="body1">
            <span>OR</span>
          </Typography>
        </Grid>
        <Grid className={classes.loginButton}>
          <Button variant="outlined" onClick={() => handleGoogleLogin()} endIcon={<FcGoogle size={30} />}>
            Login with
          </Button>
          {/* <Button
            id="microsoft-sso-login-button"
            variant="outlined"
            onClick={() => handleSsoLoginMicrosoft()}
            endIcon={<MicroSoftIcon />}
          >
            Login with
          </Button> */}
          {!isComponentLoaded && (
            <>
              <Button variant="outlined" disabled={true} endIcon={<MicroSoftIcon />}>
                Login with
              </Button>
            </>
          )}
          <div id="microsoft-sso-login-button"></div>
        </Grid>
        <p style={{ display: 'flex', justifyContent: 'center' }}>
          Forgot password?{' '}
          <Link to="/recover" style={{ marginLeft: '5px' }}>
            Reset
          </Link>
        </p>
      </Layer>
    </Layer>
  );
};

export { Login };
