import React from 'react';
import { GoCheck } from 'react-icons/go';
const PreRegistrationTitle = (props: any) => {
  const { text, sno, isDone, handleChanges } = props;
  const userData = JSON.parse(sessionStorage.getItem('userData') as string);
  return (
    <div className="s_title">
      <div>
        <span className="s_sno">{sno + 1}</span>
        <div className="s_text">
          {text}
          {isDone && <GoCheck />}
        </div>
      </div>
      {isDone &&
        !sno &&
        (userData.is_google_login || userData.is_microsoft_login ? (
          <span>
            You are login with <b>{userData.email_address}</b>
          </span>
        ) : (
          <span className="btn_change" onClick={() => handleChanges(sno)}>
            VIEW
          </span>
        ))}
      {isDone && Boolean(sno) && (
        <span className="btn_change" onClick={() => handleChanges(sno)}>
          CHANGE
        </span>
      )}
    </div>
  );
};
export default PreRegistrationTitle;
