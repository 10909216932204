import React, { useEffect, useState, useRef } from 'react';
import useFetch from 'use-http';
import UseAnimations from 'react-useanimations';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { titles, stripePublishableKey, updatedUrl, registrationStepArr, baseURL } from './../../config';
import PlanDetails from './PlanDetails';
import PaymentOptions from './PaymentOptions';
import ServiceAgreement from './ServiceAgreement';
import PreRegistrationForm from './PreRegistrationForm';
import PreRegistrationTitle from './PreRegistrationTitle';
import OrganizationInformation from './OrganizationInformation';
import CardForm from './CardForm';
import '../../styles/subscription.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { useToastStore } from 'hooks/useToastStore';
import { checkCompromisedPassword } from 'utils';

const PreRegistration = (props: any) => {
  let stripePromise: any;
  if (stripePublishableKey) {
    stripePromise = loadStripe(stripePublishableKey);
  }
  const history = useHistory();
  const userData = sessionStorage.getItem('userData');
  if (props.isLoggedIn && userData === null) {
    history.replace('/');
  }

  const params: any = useParams();
  const location: any = useLocation();

  const { planStatus } = params;
  const { request } = useFetch(updatedUrl);

  /**Page Load**/
  const [pageLoading, setPageLoading] = useState(true);
  const [isActive, setActive] = useState(0);
  const [cartItem, setCartItem] = useState<any>({});

  const [isRequiredField, setRequiredField] = useState(false);

  const planType: any = () => {
    if (typeof params === 'object' && Object.keys(params).length && typeof params.plantype === 'string') {
      return Number(params.plantype);
    } else {
      return 0;
    }
  };

  const { isOpenToast } = useToastStore();
  const googleData = useRef<any>(null);
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [inputField, setInputField] = useState<any>({
    /*Step01*/
    id: '',
    name_first: '',
    name_middle: '',
    name_last: '',
    phone: '',
    email_address: '',
    password: '',
    confirm_password: '',
    customerId: '',
    /*Step02*/
    is_consented: false,
    /*Step03*/
    organization_name: '',
    fileName: null || '',
    logo_url: '',
    description: '',
    domain_name: '',
    tag_line: '',
    organization_id: '',
    organization_ref_id: '',
    /*Coupons Code*/
    coupons_code: '',
    coupons_code_is_valid: false,
    /*Default Plan Month*/
    refer_token: '',
    subscriptionValue: planType(),
    is_google_login: false,
    is_microsoft_login: false
  });

  const { subscriptionValue, name_first, email_address, customerId, organization_ref_id } = inputField;

  useEffect(() => {
    const { state } = location;
    if (state && state.item) {
      setCartItem(state.item);
      sessionStorage.setItem('item', JSON.stringify(state.item));
    } else {
      const itemCart = sessionStorage.getItem('item');
      if (itemCart && itemCart !== '') {
        setCartItem(JSON.parse(itemCart));
      } else {
        history.replace('/plans');
      }
    }
  }, [sessionStorage]);

  useEffect(() => {
    (async () => {
      const userData = sessionStorage.getItem('userData');
      if (userData && userData !== '') {
        const response = await request.post('/pre-registration/user-info', {
          email_address: JSON.parse(userData).email_address
        });
        if (typeof response === 'object') {
          const {
            name_first,
            name_middle,
            name_last,
            phone,
            email_address,
            id,
            organization_name,
            description,
            domain_name,
            tag_line,
            is_consented,
            organization_id,
            logo_url,
            customer_id
          } = response;
          setInputField({
            ...inputField,
            name_first,
            name_middle,
            name_last,
            phone,
            email_address,
            id,
            organization_name,
            description,
            domain_name,
            tag_line,
            is_consented,
            organization_id,
            logo_url,
            customerId: customer_id
          });
          setActive(typeof planStatus === 'string' && planStatus === 'succeeded' ? 3 : 3);
        }
      }
      setPageLoading(false);
    })();
    const refererObj = sessionStorage.getItem('refererObj');
    if (refererObj && refererObj !== '') {
      const obj = JSON.parse(refererObj);
      const { email, organization_id } = obj;
      const updateObj: any = {};
      if (email) {
        updateObj.email_address = email;
      }
      if (obj.organization_name) {
        updateObj.organization_name = obj.organization_name;
      }
      if (organization_id) {
        updateObj.organization_ref_id = organization_id;
      }
      setInputField({ ...inputField, ...updateObj, subscriptionValue: planType() });
    } else {
      setInputField({ ...inputField, subscriptionValue: planType() });
    }
  }, [params, sessionStorage]);

  const setSubscriptionValue = async (value: number) => {
    setInputField({ ...inputField, subscriptionValue: value });
    sessionStorage.setItem('userData', JSON.stringify({ ...inputField, subscriptionValue: value }));
  };

  const inputsHandler = (e: any) => {
    const { name, value } = e.target;
    try {
      switch (name) {
        case 'is_consented':
          const { checked } = e.target;
          setInputField({ ...inputField, [name]: checked });
          break;
        case 'fileName':
          const { files } = e.target;
          if (files !== null) {
            setInputField({ ...inputField, [name]: files });
          }
          break;
        case 'phone': {
          let string = value?.replace(/[^0-9-]/g, '') || '';
          setInputField({ ...inputField, [name]: string });
          break;
        }
        default:
          setInputField({ ...inputField, [name]: value });
          setRequiredField(true);
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  const handleChanges = (isValue: any) => {
    setActive(isValue);
  };

  useEffect(() => {
    if (googleData.current) {
      setInputField((prev: any) => ({
        ...prev,
        name_first: googleData.current?.name_first,
        name_last: googleData.current?.name_last,
        email_address: googleData.current?.email_address,
        is_google_login: googleData.current?.is_google_login,
        is_microsoft_login: googleData.current?.is_microsoft_login
      }));
    }
  }, [googleData.current]);

  const handlePreRegistration = (token: any) => {
    const {
      name_first,
      name_middle,
      name_last,
      phone,
      email_address,
      password,
      id,
      is_google_login,
      is_microsoft_login
    } = inputField;
    const formData: any = new FormData();
    formData.append('registration_step', 1);
    formData.append('name_first', googleData.current?.name_first ? googleData.current?.name_first : name_first);
    formData.append('name_middle', name_middle);
    formData.append('name_last', googleData.current?.name_last ? googleData.current?.name_last : name_last);
    formData.append('phone', phone);
    formData.append(
      'email_address',
      googleData.current?.email_address ? googleData.current?.email_address : email_address
    );
    formData.append('password', password);
    formData.append('created_by', googleData.current?.name_first ? googleData.current?.name_first : name_first);
    formData.append('updated_by', googleData.current?.name_first ? googleData.current?.name_first : name_first);
    formData.append('recaptcha_token', token);
    formData.append('is_google_login', googleData.current?.is_google_login);
    formData.append('is_microsoft_login', googleData.current?.is_microsoft_login);
    const refer_token = localStorage.getItem('refer_token');
    if (refer_token) {
      formData.append('refer_token', refer_token);
    } else {
      formData.append('refer_token', '');
    }
    if (id !== '') {
      formData.append('id', id);
    }
    if (organization_ref_id !== '') {
      formData.append('organization_id', organization_ref_id);
    }
    return formData;
  };

  const handleServiceAgreement = () => {
    const { email_address, is_consented, name_first } = inputField;
    const formData: any = new FormData();
    formData.append('email_address', email_address);
    formData.append('is_consented', is_consented);
    formData.append('created_by', name_first);
    formData.append('updated_by', name_first);
    formData.append('registration_step', 2);
    return formData;
  };

  const handleBasicOrganizationInformation = () => {
    const {
      email_address,
      organization_name,
      fileName,
      description,
      domain_name,
      tag_line,
      name_first,
      logo_url,
      organization_id
    } = inputField;
    const formData: any = new FormData();
    formData.append('email_address', email_address);
    formData.append('organization_name', organization_name);
    formData.append('description', description);
    formData.append('domain_name', domain_name?.trim());
    formData.append('tag_line', tag_line);
    formData.append('fileName', fileName[0] ? fileName[0] : logo_url);
    formData.append('created_by', name_first);
    formData.append('updated_by', name_first);
    formData.append('registration_step', 3);
    if (cartItem) {
      const { prouct_metadata, prouct_name, product_id, subscription_type } = cartItem;
      formData.append('subscription_id', product_id);
      formData.append('subscription_name', prouct_name);
      formData.append('subscription_type', subscription_type);
      Object.keys(prouct_metadata).forEach(key => formData.append(key, prouct_metadata[key]));
    }
    if (organization_ref_id !== '') {
      formData.append('organization_id', organization_ref_id);
    } else if (organization_id) {
      formData.append('organization_id', organization_id);
    }
    return formData;
  };

  const handlePaymentOptions = async () => {
    const stripe: any = await stripePromise;
    const pageUrl = `${updatedUrl}/subscription/create-payment-checkout-session`;
    const { product_plan } = cartItem;
    const winObj: any = window;

    const Obj: any = {
      customer_id: customerId,
      domain: baseURL,
      success_url: `${baseURL}/thankyou?success=true&session_id={CHECKOUT_SESSION_ID}`,
      cancel_url: `${baseURL}/registration?canceled=true`,
      price_id: product_plan[subscriptionValue].id,
      trial_period_days: product_plan[subscriptionValue].trial_period_days
    };
    if (winObj.referral) {
      Obj.referral = winObj.referral;
    }
    const response = await fetch(pageUrl, {
      method: 'POST',
      body: JSON.stringify(Obj),
      headers: new Headers({
        'Content-type': 'application/json'
      })
    });
    if (response.ok) {
      const jsonValue = await response.json();
      const result = await stripe.redirectToCheckout({
        sessionId: jsonValue.id
      });
    } else {
      alert(titles.error);
      setPageLoading(false);
    }
  };

  const handleSubmit = async (is_google_login?: boolean, is_microsoft_login?: boolean) => {
    setPageLoading(true);
    const pageUrl = updatedUrl + '/pre-registration/init';
    if (isActive === 0) {
      let token: any;
      if (!is_google_login && !is_microsoft_login) {
        const { password } = inputField;
        const is_safe = await checkCompromisedPassword(password);
        if (is_safe) {
          isOpenToast('isError', 'The password you chose is commonly used. Please try another password');
          setPageLoading(false);
          return;
        }
        if (captchaRef.current) {
          token = captchaRef.current.getValue();
          if (!token) {
            isOpenToast('isError', "Please check the checkbox i'm not a robot.");
            setPageLoading(false);
            return;
          }
        }
      }
      const response = await fetch(pageUrl, {
        method: 'POST',
        body: handlePreRegistration(token)
      });
      const jsonValue = await response.json();
      if (jsonValue.status === '2') {
        isOpenToast('isError', jsonValue.message);
        setPageLoading(false);
        return;
      }
      if (captchaRef.current) captchaRef.current.reset();
      const refer_token = localStorage.getItem('refer_token');
      if (refer_token) {
        localStorage.removeItem('refer_token');
      }
      if (response.ok) {
        if (googleData.current) {
          const obj = {
            id: jsonValue.id,
            name_first: googleData.current?.name_first,
            name_last: googleData.current?.name_last,
            email_address: googleData.current?.email_address,
            is_google_login: googleData.current?.is_google_login,
            is_microsoft_login: googleData.current?.is_microsoft_login
          };
          setInputField((prev: any) => ({
            ...prev,
            name_first: obj.name_first,
            name_last: obj?.name_last,
            email_address: obj?.email_address,
            is_google_login: obj?.is_google_login,
            is_microsoft_login: obj?.is_microsoft_login
          }));
          sessionStorage.setItem('userData', JSON.stringify(obj));
        } else {
          setInputField({ ...inputField, id: jsonValue.id });
          sessionStorage.setItem('userData', JSON.stringify(inputField));
        }
        handleChanges(isActive + 1);
      } else {
        alert(jsonValue.message + ' Please Login to Continue');
        history.replace('/');
      }
      setPageLoading(false);
    }
    if (isActive === 1) {
      await fetch(pageUrl, {
        method: 'POST',
        body: handleServiceAgreement()
      }).then(response => {
        if (response.ok) {
          sessionStorage.setItem('userData', JSON.stringify(inputField));
          handleChanges(isActive + 1);
        } else {
          alert(titles.error);
        }
      });
      setPageLoading(false);
    }
    if (isActive === 2) {
      await fetch(pageUrl, {
        method: 'POST',
        body: handleBasicOrganizationInformation()
      }).then(async response => {
        if (response.ok) {
          const customerId = await response.text();
          setInputField({ ...inputField, customerId });
          handleChanges(isActive + 1);
          sessionStorage.setItem('userData', JSON.stringify(inputField));
        } else {
          alert(titles.error);
        }
      });
      setPageLoading(false);
    }
    if (isActive === 3) {
      handlePaymentOptions();
    }
  };

  const activeItem = (currItem: number) => {
    if (currItem === isActive) {
      switch (isActive) {
        case 0:
          return (
            <PreRegistrationForm
              organization_ref_id={organization_ref_id}
              setInputField={setInputField}
              inputField={inputField}
              inputsHandler={inputsHandler}
              handleSubmit={handleSubmit}
              captchaRef={captchaRef}
              isRequiredField={isRequiredField}
              setActive={setActive}
              googleData={googleData}
            />
          );
        case 1:
          return <ServiceAgreement inputsHandler={inputsHandler} handleSubmit={handleSubmit} inputField={inputField} />;
        case 2:
          return (
            <OrganizationInformation
              organization_ref_id={organization_ref_id}
              inputsHandler={inputsHandler}
              handleSubmit={handleSubmit}
              inputField={inputField}
              isRequiredField={isRequiredField}
            />
          );
        case 3:
          return (
            <PaymentOptions
              cartItem={cartItem}
              children={
                <CardForm
                  {...cartItem}
                  subscriptionValue={subscriptionValue}
                  setSubscriptionValue={setSubscriptionValue}
                  name="planCost01"
                />
              }
              handleSubmit={handleSubmit}
            />
          );
        default:
          return null;
      }
    }
    return null;
  };

  return (
    <div className="sLayoutRootDiv">
      {pageLoading && (
        <div className="subscription-checkout-load">
          <UseAnimations animationKey="loading" size={40} />
          <div className="txt-please-wait">Please Wait...</div>
        </div>
      )}
      <div className="sLayout">
        <div className="container">
          <div className="row g-2">
            <div className="col-lg-9 col-md-8 col-sm-12">
              {registrationStepArr.map((item, index) => {
                const isactive = isActive === index;
                return (
                  <div key={index} className={isactive ? 'sSection active' : 'sSection'}>
                    <PreRegistrationTitle
                      text={item}
                      sno={index}
                      isDone={index < isActive}
                      handleChanges={handleChanges}
                    />
                    {activeItem(index)}
                  </div>
                );
              })}
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <PlanDetails
                {...cartItem}
                children={
                  <CardForm
                    {...cartItem}
                    subscriptionValue={subscriptionValue}
                    setSubscriptionValue={setSubscriptionValue}
                    name="planCost"
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { PreRegistration };
